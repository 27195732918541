<template>
  <div class="w-100 p-2 mt-2">
    <page-header :name="'Dashboard'">
      <i class="fas fa-clipboard-list"></i> {{ $t('page.title.dashboard') }}
    </page-header>

    <div class="row row-eq-height">
      <!-- Users -->
      <div class="col-lg-3 col-6">
        <div class="small-box bg-warning">
          <div class="inner" style="min-height: 361px">
            <h4>{{ $t('page.dashboard.users') }}</h4>
            <div class="text-center text-dark my-2" v-if="usersIsBusy">
              <b-spinner class="align-middle"></b-spinner>
              <strong>{{ $t('page.dashboard.loadin') }}:</strong>
            </div>
            <div v-else>
              <table class="table b-table table-hover table-borderless">
                <tbody role="rowgroup"><!---->
                <tr role="row" class="">
                  <td class="">{{ $t('page.dashboard.allUsers') }}:</td>
                  <td class="">{{ usersData.allUsers }}</td>
                  <td></td>
                </tr>
                <tr role="row" class="">
                  <td class="">{{ $t('page.dashboard.activeUsers') }}:</td>
                  <td class="">{{ usersData.activeUsers }}</td>
                  <td></td>
                </tr>
                <tr role="row" class="">
                  <td class="">{{ $t('page.dashboard.deletedUsers') }}:</td>
                  <td class="">{{ usersData.deletedUsers }}</td>
                  <td></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="icon">
            <i class="fa-user-plus"></i>
          </div>
          <router-link :to="{'name':'clients-list'}" class="small-box-footer">
            {{ $t('page.dashboard.usersList') }}
          </router-link>
        </div>
      </div>
      <!-- Offers -->
      <div class="col-lg-3 col-6">
        <div class="small-box bg-info">
          <div class="inner" style="min-height: 388px">
            <h4>{{ $t('page.dashboard.subscriptions') }}</h4>
            <div class="text-center text-light my-2" v-if="subscriptionsIsBusy">
              <b-spinner class="align-middle"></b-spinner>
              <strong>{{ $t('page.dashboard.loading') }}:</strong>
            </div>
            <div v-else>
              <table class="table b-table table-hover table-borderless">
                <tbody role="rowgroup"><!---->
                <tr role="row" class="">
                  <td class="">{{ $t('page.dashboard.allSubscriptions') }}:</td>
                  <td class="">{{ subscriptionsData.activeSubscriptions + subscriptionsData.expiredSubscriptions}}</td>
                  <td></td>
                </tr>
                <tr role="row" class="">
                  <td class="">{{ $t('page.dashboard.activeSubscriptions') }}:</td>
                  <td class="">{{ subscriptionsData.activeSubscriptions }}</td>
                  <td></td>
                </tr>
                <tr role="row" class="">
                  <td class="">{{ $t('page.dashboard.expiredSubscriptions') }}:</td>
                  <td class="">{{ subscriptionsData.expiredSubscriptions }}</td>
                  <td></td>
                </tr>
                <tr role="row" class="">
                  <td class="">{{ $t('page.dashboard.level1Subscriptions') }}:</td>
                  <td class="">{{ subscriptionsData.level1Subscriptions }}</td>
                  <td></td>
                </tr>
                <tr role="row" class="">
                  <td class="">{{ $t('page.dashboard.level2Subscriptions') }}:</td>
                  <td class="">{{ subscriptionsData.level2Subscriptions }}</td>
                  <td></td>
                </tr>
                <tr role="row" class="">
                  <td class="">{{ $t('page.dashboard.level3Subscriptions') }}:</td>
                  <td class="">{{ subscriptionsData.level3Subscriptions }}</td>
                  <td></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="icon">
            <i class="fas fa-solid fa-signal"></i>
          </div>
        </div>
      </div>
      <!-- Inquiries -->
      <div class="col-lg-3 col-6">
        <div class="small-box bg-gradient-purple">
          <div class="inner" style="min-height: 361px">
            <h4>{{ $t('page.dashboard.rental') }}</h4>
            <div class="text-center text-light my-2" v-if="rentalIsBusy">
              <b-spinner class="align-middle"></b-spinner>
              <strong>{{ $t('page.dashboard.loading') }}</strong>
            </div>
            <div v-else>
              <table class="table b-table table-hover table-borderless table-no-padding">
                <tbody role="rowgroup"><!---->
                <tr role="row" class="">
                  <td class="">{{ $t('page.dashboard.allRental') }}:</td>
                  <td class="">{{ rentalData.activeRentals + rentalData.expiredRentals}}</td>
                  <td></td>
                </tr>
                <tr role="row" class="">
                  <td class="">{{ $t('page.dashboard.activeRentals') }}:</td>
                  <td class="">{{ rentalData.activeRentals}}</td>
                  <td></td>
                </tr>
                <tr role="row" class="">
                  <td class="">{{ $t('page.dashboard.expiredRentals') }}:</td>
                  <td class="">{{ rentalData.expiredRentals}}</td>
                  <td></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="icon">
            <i class="fas fa-solid fa-question"></i>
          </div>
          <router-link :to="{'name':'statistic', 'query': {page: 'rental'}}" class="small-box-footer">
            {{ $t('page.dashboard.renalStatisticsList') }}
          </router-link>
        </div>
      </div>
      <!-- Orders -->
      <div class="col-lg-3 col-6">
        <div class="small-box bg-success">
          <div class="inner" style="min-height: 388px">
            <h4>{{ $t('page.dashboard.content') }}:</h4>
            <div class="text-center text-light my-2" v-if="contentIsBusy">
              <b-spinner class="align-middle"></b-spinner>
              <strong>{{ $t('page.dashboard.loading') }}:</strong>
            </div>
            <div v-else>
              <table class="table b-table table-hover table-borderless">
                <tbody role="rowgroup"><!---->
                <tr role="row" class="">
                  <td class="">{{ $t('page.dashboard.scores') }}:</td>
                  <td class="">{{ contentData.scores }}</td>
                  <td></td>
                </tr>
                <tr role="row" class="">
                  <td class="">{{ $t('page.dashboard.authors') }}:</td>
                  <td class="">{{ contentData.authors }}</td>
                  <td></td>
                </tr>
                <tr role="row" class="">
                  <td class="">{{ $t('page.dashboard.masterClasses') }}:</td>
                  <td class="">{{ contentData.masterClasses }}</td>
                  <td></td>
                </tr>
                <tr role="row" class="">
                  <td class="">{{ $t('page.dashboard.lessons') }}:</td>
                  <td class="">{{ contentData.lessons }}</td>
                  <td></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="icon">
            <i class="fas fa-shopping-cart"></i>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>

import axios from "axios";
import PageHeader from "../../components/page/PageHeader.vue";
import alertify from "alertifyjs";

export default {
  components: {PageHeader},
  data() {
    return {
      rentalIsBusy: false,
      usersIsBusy: false,
      subscriptionsIsBusy: false,
      contentIsBusy: false,
      rentalData: {},
      usersData: {},
      subscriptionsData: {},
      contentData: {}
    };
  },
  methods: {
    getUsersData() {
      this.usersIsBusy = true;
      axios.get('/statistic/users')
          .then((response) => {
            this.usersData = response.data
          })
          .catch(error => {
            alertify.error(error.response.data.label);
          });
      this.usersIsBusy = false;
    },
    getSubscriptionsData() {
      this.subscriptionsIsBusy = true;
      axios.get('/statistic/subscriptions')
          .then((response) => {
            this.subscriptionsData = response.data
          })
          .catch(error => {
            alertify.error(error.response.data.label);
          });
      this.subscriptionsIsBusy = false;
    },
    getRentalData() {
      this.subscriptionsIsBusy = true;
      axios.get('/statistic/rental')
          .then((response) => {
            this.rentalData = response.data
          })
          .catch(error => {
            alertify.error(error.response.data.label);
          });
      this.subscriptionsIsBusy = false;
    },
    getContentData() {
      this.contentIsBusy = true;
      axios.get('/statistic/content')
          .then((response) => {
            console.log(response.data);
            this.contentData = response.data
          })
          .catch(error => {
            alertify.error(error.response.data.label);
          });
      this.contentIsBusy = false;
    }
  },
  mounted() {
    axios.defaults.headers.common['X-AUTH-TOKEN'] = this.apiKey;
    this.getUsersData();
    this.getSubscriptionsData();
    this.getRentalData();
    this.getContentData();
  },
  computed: {
    apiKey: function () {
      return this.$store.getters["root/apiKey"];
    },
    dashboard: function () {
      return this.$store.getters["dashboardStore/dashboard"];
    }
  }
}
</script>
